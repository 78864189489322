$text-green: #00c880 !important;
$text-greendark: #007f4e !important;
$text-gdark-normal: #808080;
$text-gdark: $text-gdark-normal !important;
$text-white: #ffffff;
$text-black: #000000 !important;
$text-pink:#f0047f !important;
$pmedium: 'Proxima-nova-medium' !important;
$plight: 'ProximaNova-Light' !important;
$pbold: 'ProximaNova-Bold' !important;
$pregu: 'ProximaNova-Regular' !important;
$brd0: none !important;

.pmedium {
  font-family: $pmedium;
}

.plight {
  font-family: $plight;
}

.pbold {
  font-family: $pbold;
}

.pregu {
  font-family: $pregu;
}

.t_green {
  color: $text-green;
}

.t_gray {
  color: $text-gdark;
}

.t_black {
  color: $text-black;
}

.t_white {
  color: $text-white !important;
}

.t_pink{
  color:$text-pink;
}

.mbtm0 {
  margin-bottom: 0;
}

.mtop0{
  margin-top:0 !important;
}
.mar0{
  margin:0 !important;
}
.pad0{
  padding:0 !important;
}
.potp0{padding-top:0 !important}
.pbtm0{padding-top:0 !important}
.pbtm0{padding-bottom:0 !important}

.rpad0{
  padding-right:0 !important;
}

.lpad0{
  padding-left:0 !important;
}
.pr-40{padding-right:40px;}
.w100{width:100%;}
.wauto{width:auto !important;}
.height60{min-height: 60px;}
.height38{min-height:38px;}
.lmm50 {
  margin-left: -50px;
}
.padl10 {
  padding-left: 10px;
}
.ml10 {
  margin-left: 10px;
}
.fnormal {
  font-weight: normal !important;
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../../fonts/ProximaNova-Bold.ttf');
}

@font-face {
  font-family: 'ProximaNova-Light';
  src: url('../../fonts/proximanovalight.ttf');
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../../fonts/ProximaNova-Regular.ttf');
}

@font-face {
  font-family: 'Proxima-nova-medium';
  src: url('../../fonts/ProximaNova-Medium.ttf');
}

%link {
  color: #00c880;
  background: transparent;
  border: none;
  text-decoration: none;
  box-shadow: none;
  font-family: $pmedium;

  &:hover, &:focus, &:active {
    color: #000 !important;
    border: none;
    text-decoration: none;
    box-shadow: none !important;
    background: transparent;
    transform: translateY(-1px);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

.link {
  @extend %link;
}

%btn{
  background: transparent;
  padding: 0;
  font-size: 16px;
  border: none;
  text-decoration: none;
  box-shadow: none;
  font-family: $pmedium;

  &:hover,&:focus,&:active{
    background: transparent !important;
    box-shadow: none !important;
  }
}

.btn{
  @extend %btn;
  &:hover{
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

a, button {
  transition: all .2s linear;
  -ms-transition: all .2s linear;
  -moz-transition: all .2s linear;
  &:hover {
    transition: all .2s linear;
    -ms-transition: all .2s linear;
    -moz-transition: all .2s linear;
  }
}

/*Common CSS*/
body {
  font-family: $pregu;
  font-weight: normal;
  background: #f2f2f2 !important;
}

h1,h2,h3,h4,h5,h6{
  font-weight:normal !important;
}

input::placeholder {
  color: #000 !important;
  font-family: $pregu;
  font-weight:normal !important;
}

.form-control {
  color: #000;
  font-family: $pregu;
}

.css-d8oujb{
  display: none;
}

.txt-green {
  color: $text-green;
}

.txt-gray {
  color: $text-gdark;
}

.txt-dgreen {
  color: $text-greendark;
}

.f10 {
  font-size: 10px;
}

.f12 {
  font-size: 12px;
}

.f14{
  font-size:14px;
}

.f16 {
  font-size: 16px;
}

.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}

.f22 {
  font-size: 22px;
}

.f26 {
  font-size: 26px;
}

.f30 {
  font-size: 30px;
}

.f32 {
  font-size: 32px;
}

.t_upper{
  text-transform: uppercase;
}

/*Css for plain-bar*/
.plain-bar {
  background: #fff;
  padding: 15px 25px;

  .d-flex {
    justify-content: space-between;
    align-items: center;
  }
}

/*CSS FOR MAIN HEADER*/
.topbar {
  height: auto !important;
  padding: 0 14px;
  border-bottom: 1px solid #97979733;
  box-shadow: none;

  .top-navbar {
    min-height: auto !important;

    .navbar-header {
      width: 100px;
      line-height: 45px !important;
    }

    .nav-item {
      &:hover {
        background: transparent !important;
      }

      .nav-link {
        color: #186e3f !important;
        font-family: $pmedium;
        padding: 0 5px !important;
        height: auto !important;
        line-height: 80px !important;
        &:hover {
          color: #91d8b7 !important;
        }
      }
    }
    .dropdown-item {
      i {
        color: $text-green;
      }
    }
    .dropdown-item.active, .dropdown-item:active {
      color: #5ec696;
      text-decoration: none;
      background-color: transparent;
      outline: none;
    }
    .user-dd {
      min-width: 200px;
      background: #00c880;
      border-radius: 15px;
      padding: 0 !important;
      margin-top: 6px;
      right: -16px;

      .nav {
        display: block;

        .nav-item {
          display: block;
        }
        .nav-link {
          cursor: pointer;
          position: relative;
          color: #fff !important;
          padding: 15px 25px !important;
          display: block;
          line-height: 25px !important;
          border-bottom: 1px solid #ffffff55;

          &:after {
            content: "\e606";
            font-family: 'simple-line-icons';
            font-size: 11px;
            float: right;
          }

          &:hover {
            color: #ccf4e6 !important;
          }
        }
      }

      .with-arrow > span {
        background-color: #08c880;
        width: 10px;
        height: 10px;
        top: 7px;
        left: 10px;
        position: absolute;
        content: '';
        border-radius: 1px 0 0 0;
      }
    }
    .profile {
      display: flex;
      align-items: center;

      .letter-badge {
        background: #ccf4e6;
        color: $text-green;
        width: 45px;
        height: 45px;
        margin: 0 7px 0 15px;
        border-radius: 100%;
        border: 2px solid $text-green;
        text-align: center;
        display: inline-block;
        font-size: 18px;
        line-height: 40px;
      }

      .avtar {
        img {
          background: #ccf4e6;
          width: 45px;
          height: 45px;
          border-radius: 100%;
          margin: 0 7px 0 15px;
          border: 2px solid $text-green;
        }
      }
    }
  }
}

.page-wrapper {
  background: #f2f2f2;
}

/*CSS FOR MAIN WRAPPER*/
.rect_bx {
  margin-bottom: 30px;
  border-radius: 5px;
  background-color: #ffffff;
  /*box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);*/
  /* border: 1px solid #fff; */

  .head_tron{
    padding:32px 40px;
    border-bottom: 1px solid #97979733;

    .head{
      font-size: 16px;
      margin:0 !important;
      font-family:$pmedium;
    }

    .fx_w {
      width: 192px;
      min-width: 192px;
    }

    .tab_grid{

      span{
        color: #000 !important;
        text-transform: uppercase;
      }
    }
  }

  .tab_grid{
    justify-content: space-between;
    width: 100%;

    span{
      width: 25%;
      display: inline-block;
      color: $text-gdark-normal;
      font-size: 12px;
    }
  }

  .loan_costs {
    .tab_grid {
      span {
        width: 50%;
      }
    }
  }

  .progress{
    border-radius:20px;
    margin-left:.75em;
    background: #ccf4e6;
    width:100%;

    .progress-bar{
      background-color: #00c880;
    }
  }

  .progress-no {
    min-width: 70px;
    width: 70px;
    text-align: right;
    font-size: 16px;
    padding-right: 10px;
  }

  .d-flex{
    align-items: center;
  }

  .tp_row{
    padding:10.5px 40px;
    border-bottom: 1px solid #97979733;

    .sm_tit{
      margin-bottom:0 ;
      font-size:14px;
      color: $text-gdark;
      font-family:$pregu;
    }

    .fx_w {
      width: 192px;
      min-width: 192px;
    }

    .form-control{
      border:none !important;
      min-height:38px;
    }

    .slct{
      width:100%;
    }

    .p_type_opt,.p_type_cat{
      width: 100%;
      padding: 10.5px 0;
      font-family: $pmedium;
    }
    .p_type_cat{
      border-left: 1px solid #97979733;
    }

    .right-icon {
      width: 20px;
      min-width: 20px;
    }

    .loc_type_opt{
      width: 100px;
      font-family: $pmedium;
    }

    .inner_row{
      margin-left: 10px;

      .brd-top{
        border-top: 1px solid #97979733;
      }
    }

    .act_icon{
      padding: 18px 48px;
      line-height: 22px;
    }

    .value{
      padding-right: 30px;
    }

    .custom-control-label::before{
      border: 1px solid #dee2e6;
      background-color:#fff !important;
    }

    .custom-control-input:checked ~ .custom-control-label::before,.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
      background-color: #00c880 !important;
    }

    .custom-control-label::after, .custom-control-label::before{
      top: -0.85rem;
      left: -13px;
      border-radius: 100%;
    }


    .inner_select{
      padding-right: 20px;

      .css-10nd86i{
        width: 100%;

        .css-1hwfws3{
          padding-left: 0;
        }
        .css-xp4uvy{
          font-size: 15px;
          color: $text-gdark;
          font-family: $pmedium;
        }
      }

      .css-1rtrksz {
        padding-left: 0 !important;
        margin-left: -1px !important;
      }

      .css-1wy0on6 {
        padding-right: 12px !important;
      }
    }
  }

  .rotate{
    i{
      transform: rotate(45deg);
      display: inline-block;
      font-size: 21px;
      position: relative;
      top: 5px;
      right: -15px;
    }
  }

  .amenities{

    .hd_row{
      padding:10.5px 40px;
      width: 33%;
      min-width: 33%;
      max-width: 33%;
      align-items: center;

      .form-control{
        padding: 0;
        text-align: center;
      }

      .sm_tit {
        padding-right: 0px;
      }
    }
  }

  .tp_row_btm {

    .left_bx{
      padding: 25px 35px;
      border-left: 1px solid #f2f2f2;
      text-align: right;
      width: 145px;
      margin: 0 0 0 auto;

      .btn{
        padding-right: 0;
        letter-spacing: 1px;
      }
    }

    .right_bx{
      border-right: 1px solid #f2f2f2;
      padding: 25px 35px;
    }

    .center_bx .dlt_btn:hover{
      color:#fff !important;
    }

    .btn:hover{
      color:#04b575 !important;
    }

    .jumbo_btn{
      /*padding: 32px 40px;*/
      padding: 25px 35px;
      display: block;
      border: none;
      border-radius: 0 0 5px 5px;
      letter-spacing: 1px;

      &:hover, &.active{
        background: $text-green;
        color:#fff !important;
      }
    }
  }

  .custom-tag{
    /*height: 480px;*/
  }

  .crita{
    width: 25%;
    height: 20px;
    position: relative;
    top: -9px;

    .css-10nd86i{
      background: #00c880;
      width: 85px;
      border-radius: 20px;

      .css-ig267l{
        justify-content: end;
        width: 85px;
        border-radius: 20px;
        background: #00c880;
      }

      .css-1492t68, .css-va7pk8{
        color: #ffffff;
      }
    }

    .css-xp4uvy,svg {
      color:#fff !important;
    }
  }
}

.popup-wrap {

  .btn-loading {
    background: #08c880 !important;
  }

  .t_green {
    color: #fff !important;
  }

  .rect_bx{
    border:3px solid $text-green;
    border-radius:5px;
    background: #08c880;

    .tp_row{
      border-bottom: 1px solid #41ce99;

      .form-control {
        color: #fff;
        background: transparent;

        &::placeholder {
          color: #ffffff !important;
          opacity: 1;
        }
      }

    }

    .inner_row .brd-top {
      border-top: 1px solid #41ce99;
    }

    .head_tron{
      border-bottom: 1px solid #41ce99;
    }
    .head,.sm_tit,.loc_tab span,.tab_grid span,.txt-gray,.t_black{
      color: #fff !important;
    }

    .btn:hover{
      color: #171717 !important;
    }

    .tp_row_btm .right_bx{
      border-right: 1px solid #41ce99;
    }

    .left_bx{
      border-left: 1px solid #41ce99;
    }
  }

  .rotate{

    i{
      right:0;
      top: 3+px;
    }
  }

  .loc_tab{

    span{
      cursor:pointer;
      color: #adadad !important;
      font-family: $pmedium;

      &:hover, &.active{
        color:$text-white;
      }
    }
  }

  .tab_grid span{
    font-size:15px;
    font-family: $pmedium;
  }
}

.stamp_duty{

  .tab_grid{
    span:last-child{
      text-align: center;
      width: 60px;
    }
  }
}

.tax_benefits, .taxable_income, .tax_scales, .tax_offset{

  .tab_grid{
    span:last-child{
      text-align: center;
      width: 100px;
    }
  }
}

.loan_cost{

  .tab_grid{
    span:last-child{
      text-align: center;
      width: 100px;
    }
  }
}

.prpty_snapshot {

  .head_tron{
    justify-content: space-between;
  }

  .collapse_grid span{
    color:#bfbfbf;
    padding-left: 15px;
    font-size: 18px;

    &:hover,.active{
      color:$text-green;
    }
  }

  .map {
    height: 480px;
  }

  .default-img-bg {
    background: #2f3438;
  }

  .carousel-item {
    &.default {
      height: 480px;
    }


    .default-img {
      height: 480px !important;
      width: 340px;
      margin-left: calc(50% - 170px);
      margin-right: calc(50% - 170px);
      padding-top: 100px;
      padding-bottom: 100px;
      opacity: 0.9;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .upload-imgs {
    position: relative;
    margin-top: -60px;
    margin-left: calc(100% - 60px);
    z-index: 9999999999999999;
    width: 60px;
    height: 60px;
    background: #000;
    padding: 9px 11px;

    &:hover {
      cursor: pointer;
    }

    .icon-picture {
      font-size: 40px;
      color: $text-white;
    }
  }

  .upload-section {
    padding: 10.5px 40px;
    /*height: 480px;*/
    height: auto;

    .uploading-image-loader {
      border: 7px solid #e0e0e0;
      border-top: 7px solid $text-greendark;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 2s linear infinite;
      position: absolute;
      left: calc(50% - 10px);
      top: calc(50% - 15px);
    }

    .uploading-image-main-loader {
      border: 7px solid #e0e0e0;
      border-top: 7px solid $text-greendark;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 2s linear infinite;
      margin-left: calc(50% - 10px);
      margin-top: 30px;
      margin-bottom: 25px;
    }

    .upload {
      width: 100%;
      background: #f1f1f1;
      border: 2px #d3d3d3 dashed;
      border-radius: 5px;
      margin: 0;
      margin-top: 100px;
      padding: 60px 0 70px;

      .file-drop-target {
        font-size: 14px;
        text-align: center;

        .primary-text {
          color: $text-green;
        }

        img {
          width: 85px;
          display: block;
          margin: auto;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .uploaded-image {
      width: 100%;
      /*height: 300px;*/
      height: 100%;
      border-radius: 5px;
      position: relative;

      .remove-image {
        background: $text-green;
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        width: 50px;
        height: 50px;
        padding: 0 7.5px 5px 7.5px;

        i {
          font-size: 35px;
          color: #ffffff;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &:hover > .remove-image {
        display: block;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .preview {
      height: 130px;
      padding-bottom: 10px;
      width: auto;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;

      .preview-block {
        position: relative;
        width: 240px;
        min-width: 240px;
        height: 120px;
        padding: 0 10px 0 10px;

        .file-drop {
          width: 100%;
          height: 100%;
          background: #f1f1f1;
          border: 2px #d3d3d3 dashed;
          border-radius: 5px;

          .file-drop-target {
            width: 100%;
            height: 100%;

            img {
              width: 50px;
              margin-left: calc(50% - 25px);
              margin-top: calc(50% - 75px);
            }
          }
        }

        .preview-uploaded {
          width: 100%;
          height: 100%;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        .remove-image {
          background: $text-green;
          position: absolute;
          top: 0;
          right: 10px;
          display: none;
          width: 30px;
          height: 30px;
          padding: 0 5px 3px 5px;

          i {
            font-size: 20px;
            color: #ffffff;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &:hover > .remove-image {
          display: block;
        }
      }
    }

    .done-btn {
      background: $text-green;
      color: $text-white;
      padding: 5px 35px;
      border-radius: 30px;
      margin-top: -100px;
      margin-left: calc(100% - 135px);
    }
  }
}

.place-card.place-card-large,.login-control{
  display:none !important;
}

.prpty_projection {

  .hd_row{
    /*padding-top: 9px;
    padding-bottom: 9px;*/
    padding-top: 10px;
    padding-bottom: 11px;
  }

  .rect_bx .head_tron {
    /*padding: 23px 40px !important;*/
  }
}

/*CSS FOR SIDEBAR*/
.left-sidebar{
  width: 80px;
  padding-top: 80px;
  .scroll-sidebar{
    overflow: visible;
  }
  .ps{
    overflow: visible !important;
  }
  .sidebar-nav {
    .sidebar-item{
      .sidebar-link{
        color: #000;
        letter-spacing: 1px;
        font-size: 15px;
        white-space: nowrap;
        position: relative;
        display: flex;
        opacity:1 !important;
        height: 60px;
        align-items: center;
        justify-content: center;
        transition: color 0s linear !important;
        padding: 18px 0;
        border-bottom: 1px solid #97979733;
        background-color: #fff;
        text-transform: capitalize;

        &:hover{
          background-color: rgb(247, 247, 247);
          span{
            left: 100%;
            transition: all .3s ease-in;
            box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
          }
        }

        span{
          font-family: $pmedium;
          position: absolute;
          top: 0;
          left: -14em;
          height: 60px;
          line-height: 60px;
          padding: 0 20px;
          z-index: -9;
          background:$text-green;
          border-radius: 0 8px 8px 0;
          color: #fff;
          font-size: 15px;
          transition: all .3s ease-in;
        }

        img{
          width:20px;
        }
      }
    }
  }
}

/*CSS FOR FLIP BOX*/
.prpty_flipper{
  position: relative;

  .grd_bx{
    position: absolute;
    width: 100%;
    top: 0;
    opacity: 0;
    -ms-transform: rotateY(90deg);
    -ms-transform-origin:50% 50%;
    transform: rotateY(90deg);
    transform-origin:50% 50%;
  }

  .flip_bx.go,.grd_bx.go{
    -webkit-transform-origin:50% 50%;
    -moz-transform-origin:50% 50%;
    -ms-transform-origin:50% 50%;
  }

  .flip_bx.go{
    -webkit-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    transform: rotateY(90deg);
    -webkit-transition: all .4s linear;
    -ms-transition: all .4s linear;
    transition: all .4s linear;
  }

  .flip_bx.go{
    display: none;
  }

  .grd_bx.go{
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transition: all .6s  linear;
    -ms-transition: all .6s  linear;
    transition: all .6s  linear;
    opacity: 1;
    position: relative;
  }
}

/*CSS FOR PAGE-WRAPPER*/
.page-wrapper > .page-content{
  padding-left: 6.8rem;
}

/*CSS FOR LOGIN*/
.auth-box {
  box-shadow: none !important;
  max-width: 650px !important;
  background: transparent !important;
  margin: 5% auto !important;
  padding: 0 !important;

  .card {
    margin: 40px auto;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 5px 5px 20px 0 rgba(155, 155, 155, 0.1) !important;

    .card-body {
      padding: 0 !important;
    }
  }

  .card-title {
    font-family: 'ProximaNova-Light';
    font-weight: normal;
    color: #007f4e;
    letter-spacing: 1px;
  }

  .tab-content {
    border-radius: 0 0 5px 5px;
  }
  ul {
    background: #e6e6e6;
    border-bottom: 1px solid #f2f2f2;
    text-align: center;
    border-radius: 10px 10px 0 0;
  }

  .nav-item {
    /* border: 1px solid #6f6f6f; */
    border-bottom: none;
    border: none !important;
    /* border-radius: 8px 37px 0 0; */
    /* margin: 0 2em; */
    display: block;
    /* margin-left: -4px; */
    /* float: left; */
    display: inline-block;
    position: relative;
    /* z-index: -9; */
    width: 50%;
  }

  .nav-item a {
    color: #00c880 !important;
    border: none !important;
    background: #e6e6e6 !important;
    letter-spacing: 1.2px !important;
    padding: 30px 10px;
    /* font-weight: 700; */
    display: block;
    font-size: 16px;
    /* line-height: 2.2em; */
    /* padding: 0 1.05vw; */
    /* text-transform: uppercase; */
    white-space: nowrap;
    cursor: pointer;
    border-bottom: 1px solid #f2f2f2 !important;
  }

  ul .nav-item:first-child a {
    border-radius: 5px 0 0 0;
    border-right: 1px solid #f1f1f1 !important;
  }
  ul .nav-item:last-child a {
    border-radius: 0 5px 0 0;
  }
  ul .nav-item .active.nav-link {
    background: #fff !important;
  }
  .input-group {
    padding: 15px 25px;
    border-bottom: 1px solid #f2f2f2 !important;
  }

  .form-control, .input-group-prepend .input-group-text {
    border: none !important;
  }

  .input-group-prepend .input-group-text {
    min-width: 180px;
    background: transparent !important;
    color: #808080;
  }
  .auth-footer {
    justify-content: space-between;
    align-items: center;

    .right_bx {
      width: 100%;

      .fb {
        color: #3c5a99;
        background:none!important;
        border:none;
        padding:0!important;
        font: inherit;
        &:hover {
          cursor: pointer;
        }
      }

      .in {
        color: #0077b5;
        background:none!important;
        border:none;
        padding:0!important;
        font: inherit;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .btn {
      background: transparent;
      padding: 0;
      font-size: 16px;
      @extend %link;
    }

    .right_bx {
      padding: 25px 0 25px 35px;
    }
    .left_bx {
      padding: 25px 35px;
      border-left: 1px solid #f2f2f2;
    }

    .social:last-child {
      margin-left: 30px;
    }

    .social span{
      vertical-align: middle;
    }

    .social img {
      padding-right: 5px;
      margin-right: 5px;
    }
  }

}

/* Switch button */
.field {text-align: center;}
.field:first-child {
  border-top: none;
}
input[type="checkbox"] {
  display: none;
}
.field {
      align-items: center;
   display: -ms-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
}
.field label {
      position: relative;
    line-height: 32px;
    display: inline-block;
    margin: 0 15px;
}
.field label:before,
.field label:after,
.field label span:before,
.field label span:after {
  content: '';
  display: block;
  position: absolute;
}
.field label span {
  position: relative;
  cursor: pointer;
  display: block;
}
 
.check-round label span {
  border-radius: 16px;
  height: 30px;
  width: 64px;
  margin: 0 auto;
}
.check-round label span:before {
  border-radius: 50%;
  height: 26px;
  width: 26px;
  left: 2px;
  top: 2px;
}
.check-round input:checked + label span:before {
  left: 36px;
}
.check-round.slide label span {
  background: #00c880;
  transition: background 0.4s ease-in;
}
.check-round.slide label span:before {
  background: #ECF0F1;
  transition: left 0.4s ease-in;
}
.check-round.slide input:checked + label span {
  background: #007f4e;
}
.check-round.slide-inverse label span {
  background: #ECF0F1;
  /* border: 1px solid #bdc3c7; */
  background: rgba(216, 216, 216, .5);
  opacity: 1;
}
.check-round.slide-inverse label span:before {
  background: #00c880;
  transition: left 0.4s ease-in, background 0.4s ease-in;
}
.check-round.slide-inverse input:checked + label span:before {
  background: #007f4e;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 10px;
  margin-bottom:0;
  top: 2px;
}

.switch input {
  top: -2px;
  z-index: 9;
  width: 100%;
  opacity: 0;
  height:auto;
  left:0;
}

.switch .custom-control-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 20px;
  height: 6px;
}
.switch  .custom-checkbox.custom-control{
  min-height:auto !important;
}
.switch .custom-control-label:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: -3px !important;
  border: none !important;
  top: -6px !important;
  background: #fff !important;
  box-shadow: 0 1px 10px #33333344 !important;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked + .custom-control-label {
  background-color: #00c880;
}

.switch input:focus + .custom-control-label {
  box-shadow: 0 0 1px #bfbfbf;
}

.rect_bx .tp_row .switch .custom-control-input:checked ~ .custom-control-label::before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
  background-color: #fff !important;
  box-shadow: 0 1px 10px #33333344;
}
.custom-control-label.round1 {
  border-radius: 34px;
}

.switch .custom-control-label.round1:before {
  border-radius: 50%;
}

.switch-left {
  margin-right: 10px;
}

.switch-right {
  margin-left: 10px;
}

.sts {
  padding: 0.375rem 0.75rem;
}

/* CSS for user detail */
.user-img {
  width: 100px;
  margin: 20px auto 20px;
  position: relative;

  .avtar {
    background: #000;
    border-radius: 100%;
    width: 80px;
    height: 80px;
    text-align: center;
    margin: 0 auto;
    box-shadow: 0 2px 27px #33333388;

    img {
      margin: 0 auto;
      padding: 19px 0;
    }
  }

  .avtar-img{
    background: #000;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    text-align:center;
    margin: 0 auto;
    box-shadow: 0 2px 27px #33333388;
    overflow: hidden;
    img{
      margin: 0 auto;
      width: 100%;
      height: 100%;
    }
  }

  .change-img {
    text-align: center;
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 7px;
    bottom: 3px;
    border-radius: 100%;
    background: #fff;

    i {
      font-size: 10px;
      position: absolute;
      top: 5px;
      left: 6px;
    }
  }

}

#userdetail {
  .right_bx {
    padding: 25px 35px 25px 35px;
    width: auto;
    border-right: 1px solid #f2f2f2;

    a {
      color: #808080;
      font-size: 16px;
      font-family: $pmedium;

      &:hover {
        color: #000;
      }
    }
  }

  .dlt_btn {
    color: #000;
    font-weight: 400;
    font-family: $pregu;
    text-transform: uppercase;
    border: 1px solid #000;
    padding: 11px 30px 10px;
    border-radius: 20px;
    font-size: 12px;
    letter-spacing: 1px;

    &:hover {
      background: #000 !important;
      color: #fff !important;
    }
  }
}

/*CSS FOR CLIENT LIST PAGE*/
.page-content {
  padding: 30px;
}

.client_wrap {
  .sm_bx {
    padding: 40px;
    height: 328px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    border-radius: 5px;
    margin-bottom: 30px;

    .bx_info {
      color: $text-white;
      display: grid;
      height: 100%;
      align-items: flex-start;
      font-family: $pregu;
      font-weight: normal;

      .al_btm{
        display: flex;
        align-items: flex-end;
        height: 100%;
      }

      .num {
        font-family: $pregu;
        font-weight: normal;
        color: $text-white;
        font-size: 60px;
        margin-bottom: 0;
        text-shadow: 0 1px 1px #33333366;
      }
    }
  }
  .grid_bx .num {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }

  .wt_bx {
    background: #fff;
    border-radius: 5px;

    .top_part {
      border-bottom: 1px solid #f2f2f2;
      padding: 35px;

      .prpt_img {
        width: 110px;
        height: 110px;
        border-radius: 100%;
      }
      .top_dtl {
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;

        .top_mdl {
          width: 70%;
          .prop_add {
            padding-left: 50px;
            color: #808080 !important;
          }
          .pro_faci {
            li {
              display: inline-block;
              padding: 5px 45px 0 0;
              color: #808080 !important;
              .no {
                color: #000;
                font-family: $pmedium;
                padding-left: 15px;
              }
            }
          }
        }

        .ttl_count{
          text-align:center;
          width:50%;
          padding: 18px;
          color:$text-gdark;
          border-right:1px solid #f2f2f2;
          .ttl_num{
            color:#00c880;
            font-size: 70px;
            font-weight: normal !important;
          }
        }
      }
    }

    .middle_part{
      border-bottom:1px solid #f2f2f2;
      padding:20px 30px;
    }

    .btm_part {
      padding: 30px 35px;

      .btm-dtl {
        align-items: center;

        .search_by{
          width: 300px;
          display: flex;
          align-items: center;

          .css-10nd86i{
            width: 55%;
            background-color: transparent;
            border: none;
            height: auto !important;
            padding: 0 !important ;

            .css-1492t68, .css-va7pk8{
              color: hsl(158, 100%, 39%);
            }

            .css-1hwfws3{
              padding: 2px 4px;
              .css-xp4uvy{
                color: hsl(158, 100%, 39%);
                border-bottom: 1px solid #00c880 !important;
              }
            }
            .css-1wy0on6{
              div{
                padding: 2px;
              }
              svg{
                color:hsl(158, 100%, 39%);
                padding: 0;
                margin: 0;
              }
            }


            .css-2o5izw{
              border:none !important;
              outline: none !important;
            }
          }

          select,select:focus{
            border:none !important;
            outline:none !important;
          }
        }

        .q_search {
          width: 100%;
          position: relative;

          input {
            border: none;
            color: $text-black;
            background: #f5f4f5;
            border-radius: 20px;
            height: 35px !important;
            font-size: 12px;
            letter-spacing: 1px;
            padding: 6px 5px 5px 40px;
          }
          button {
            background: transparent;
            border: none;
            color: #000;
            box-shadow: none;
            transform: rotate(90deg);
            position: absolute;
            left: 19px;
            top: 10px;
            font-size: 10px;
            padding: 0;
          }
        }
      }
    }
  }
  .ttl_prpty {
    background: url(../../../assets/images/property/1.png)
  }
  .ttl_client {
    background: url(../../../assets/images/property/2.png)
  }

  .icon_select_mate {
    position: absolute;
    top: 20px;
    right: 2%;
    font-size: 16px;
    height: 22px;
    transition: all 275ms;
  }

  .select_mate {
    position: relative;
    float: left;
    min-width: 300px;
    width: 300px;
    min-height: 60px;
    font-family: 'Roboto';
    color: #777;
    font-weight: 300;
    background-color: #fff;
    box-shadow: 1px 2px 10px -2px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    transition: all 375ms ease-in-out;
    /* Oculto el elemento select */
    select {
      position: absolute;
      overflow: hidden;
      height: 0px;
      opacity: 0;
      z-index: -1;
    }
  }

  .cont_list_select_mate {
    position: relative;
    float: left;
    width: 100%;
  }

  .cont_select_int {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 999;
    overflow: hidden;
    height: 0px;
    width: 100%;
    background-color: #fff;
    padding: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    border-radius: 0px 0px 3px 3px;
    box-shadow: 1px 4px 10px -2px rgba(0, 0, 0, 0.2);
    transition: all 375ms ease-in-out;

    li {
      position: relative;
      float: left;
      width: 96%;
      border-bottom: 1px solid #E0E0E0;
      background-color: #F5F5F5;
      list-style-type: none;
      padding: 10px 2%;
      margin: 0px;
      transition: all 275ms ease-in-out;
      display: block;
      cursor: pointer;

      &:last-child {
        border-radius: 3px;
        border-bottom: 0px;

      }
      &:hover {
        background-color: #EEEEEE;
      }
    }

    .active {
      background-color: #EEEEEE;
    }

  }

  /* etiqueta <p> con la opcion selecionada  */

  .selecionado_opcion {
    padding: 20px 2%;
    width: 96%;
    display: block;
    margin: 0px;
    cursor: pointer;
  }

  @-webkit-keyframes load {
    0% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes load {
    0% {
      stroke-dashoffset: 0;
    }
  }

  .table-wrap {
    table {
      background: #fff;
      border-radius: 5px;
      tr {
        th {
          font-family: $pregu;
          border: $brd0;
          color: $text-gdark;
          font-weight: normal;
          border-bottom: 1px solid #f2f2f2 !important;

          &:first-child {
            padding-left: 20px;
          }

          &:last-child {
            padding-right: 20px;
          }
        }
        td {
          font-family: $pregu;
          border: $brd0;
          border-bottom: 1px solid #f2f2f2 !important;
          font-size: 14px;
          font-weight: normal;
          line-height: 2;
          letter-spacing: normal;
          color: #808080 !important;
          vertical-align: middle;

          &:first-child {
            padding-left: 30px;
          }

          &:last-child {
            padding-right: 30px;
          }

          a {
            color: #808080 !important;
          }

          .link-button {

            &:hover > a, &:focus > a {
              background: #ccf4e6 !important;
              color: #00c880 !important;
              box-shadow: none !important;
            }

            a {
              color: #000 !important;

              &:hover, &:focus {
                background: #ccf4e6 !important;
                color: #00c880 !important;
                box-shadow: none !important;
              }
            }
          }

          .trash-icon {
            background:none!important;
            border:none;
            padding:0!important;
            font: inherit;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .blank td {
        padding: 1px 0 0 0;
        background: #f2f2f2;
      }

      a {
        &.tab_btn {
          padding: 9px 30px;
          color: #000 !important;
        }
      }

      .tab_btn {
        background: #f5f4f5;
        border-radius: 20px;
        color: #000;
        padding: 6px 30px 5px;
        border: $brd0;
        box-shadow: none;
        font-family: $pregu;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;

        &:focus, &:hover {
          background: #ccf4e6 !important;
          color: #00c880;
          box-shadow: none !important;
        }

        &.active {
          background: $text-green;
          color:#fff !important;
          box-shadow: none !important;

          &:focus, &:hover {
            background: #ccf4e6 !important;
            color: #000 !important;
          }
        }
      }
      .read-more{
        position: relative;
        float: right;
        .more-icon {
          background: #393e44;
          display: block;
          height: 1px;
          margin-top: 4px;
          width: 10px;
        }
        .dropdown-menu {
          min-width: 140px;
          left: auto;
          right: 100%;
          border-radius: 5px;
          top: -9px;
          box-shadow: 0 1px 15px #33333333;
          .nav-link{
            cursor:pointer;
            padding-right: 0 !important;
            &:hover{
              color:$text-green;
            }
          }
        }
      }
      .collapse_row{
        background: #F9F9F9;
        display: none;

        .link {
          &:hover {
            cursor: pointer;
          }
        }

        td{
          vertical-align: top;
        }
      }

      .collapse_row.open{
        display: table-row;
      }

      .collapse_row:last-child td{
        border-bottom: 0 !important;
      }

      .main_row.active{
        .clickme i{
          transform: rotate(90deg);
          display: block;
          transition:all .3s linear;
          -ms-transition:all .3s linear;
        }
      }
    }
  }
  .over {
    content: attr(data-percent);
    position: absolute;
    width: 100%;
    font-family: 'ProximaNova-Light';
    top: 8px;
    left: 8px;
    text-align: center;
    width: 88px;
    text-align: center;
    align-items: center;
    display: flex;
    height: 88px;
    color: #3264ff;
    justify-content: center;
    background-color: #fff;
    border-radius: 100%;
  }
  .table-image {
    position: relative;

    svg {
      width: 6.5rem;
      height: 6.5rem;
      overflow: visible;
    }

    svg:nth-child(2) {
      position: absolute;
      position: absolute;
      left: 0;
      top: 0;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
    svg:nth-child(2) path {
      fill: none;
      stroke-width: 10px;
      stroke-dasharray: 629;
      stroke: #e8e8e8;
      opacity: 1;
      -webkit-animation: load 6s;
      animation: load 6s;
    }

    svg g {
      stroke-width: 9.5;
    }
    .data-percent {
      span {
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -ms-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        -ms-text-fill-color: transparent;
        text-fill-color: transparent;
        background-image: linear-gradient(261deg, #00c880, #3264ff);
      }
      img {
        width: 90px;
        height: 90px;
        border-radius: 100%;
      }
    }

    .incomp span {
      background-image: linear-gradient(261deg, #9c9c9c, #9c9c9c);
    }
  }
}

.topbar .top-navbar .navbar-header .navbar-brand .dark-logo {
  display: block;
  width: 45px;
}

/* Button Loading */
.btn-loading {
  position: absolute;
  width: calc(100% + 70px);
  height: calc(100% + 50px);
  left: -35px;
  top: -25px;
  padding: 0;
  margin: 0;
  background: #ffffff;

  &.right {
    border-bottom-right-radius: 5px;
  }

  &.left {
    border-bottom-left-radius: 5px;
  }

  .btn-loader{
    border: 7px solid #e0e0e0;
    border-top: 7px solid $text-greendark;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    position: absolute;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
  }
}
.jumbo_btn {
  .btn-loading {
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
  }
}

.btn-s-loading {
  width: calc(100% + 8px);
  height: calc(100% + 26px);
  left: -8px;
  top: -13px;
}

/* Page loader */
.page-loader{
  border: 7px solid #ffffff;
  border-top: 7px solid $text-greendark;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  position: absolute;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
}

.table-loader{
  border: 7px solid #ffffff;
  border-top: 7px solid $text-greendark;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin-left: calc(50% - 30px);
  margin-top: 50px;
  margin-bottom: 50px;
}

.input-loader{
  border: 7px solid #ffffff;
  border-top: 7px solid $text-greendark;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  position: re;
  margin-top: -30px;
  margin-left: calc(100% - 30px);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*Notification related*/
.notification-danger {
  background-color: #ffffff !important;
  border-left: 8px solid #e6e6e6 !important;

  .notification-close span, .notification-message, .notification-title {
    color: #808080 !important;
  }
}

.notification-success {
  background-color: $text-green;
  border-left: 8px solid $text-greendark;

  .notification-close span, .notification-message, .notification-title {
    color: #ffffff !important;
  }
}

.page-item.active {
  .page-link {
    background-color: $text-green;
    border-color: $text-green;
  }
}

.react-confirm-alert-body {
  font-family: $pregu;
  text-align: center;

  h1 {
    font-size: 20px;
    text-align: center;
    color: $text-greendark;
    font-family: $pregu;
  }

  .react-confirm-alert-button-group {
    text-align: center;
    display: block;

    button {
      color: #000;
      font-weight: 400;
      font-family: $pregu;
      text-transform: uppercase;
      border: 1px solid #000;
      padding: 5px 30px 5px;
      border-radius: 20px;
      font-size: 14px;
      letter-spacing: .5px;
      background: #ffffff;

      &:hover,&:active {
        background: #000;
        color: #fff !important;
      }
    }
  }
}


#prpty_list .table-wrap table .read-more .nav{
  justify-content: center;
}
#prpty_list .table-wrap table .read-more .nav-link{
  padding: .5rem 1rem !important;
}


/*CSSS FOR SWITCHES*/
.switches {
  .s_cat{
    color:$text-gdark;
    font-family:$pmedium;
    padding: 0 12px;
    letter-spacing: 1.2px;
  }
  .bootstrap-switch{
    background: #00c880;
    padding: 2px;
    border-radius: 25px;
    width: 80px !important;
    border:none;
    .bootstrap-switch-handle-on.bootstrap-switch-primary,.bootstrap-switch-handle-off.bootstrap-switch-default{
      background: #ffffff;
      border-radius: 100%;
      height: 37px !important;
      padding: 0px 7px;
      min-width: 37px !important;
      line-height: 11px !important;

    }

    .bootstrap-switch-label{
      background: #00c880;
    }

    .bootstrap-switch-default {
      position: relative;
      right: -2px;

      .bootstrap-switch-focused .bootstrap-switch-container {
        margin-left: -39px !important;
      }

      .bootstrap-switch-animate .bootstrap-switch-default {
        position: relative;
        right: -4px;
      }
    }
  }

  .bootstrap-switch-on {
    .bootstrap-switch-handle-off {
      background: #00c880 !important;
      animation: colorChangeAnimation 0.4s ease-in 3s forwards;
      animation: colorChangeAnimation 0.4s ease-in 3s forwards;
    }
    .bootstrap-switch-handle-on {
      background: #ffffff !important;
    }
  }

  .bootstrap-switch-off {
    .bootstrap-switch-handle-off {
      background: #ffffff !important;
    }
    .bootstrap-switch-handle-on {
      background: #00c880 !important;
      animation: colorChangeAnimation 0s ease-in 3s forwards;
    }
  }

  .bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
    -webkit-transition: margin-left .7s !important;
    transition: margin-left .7s !important;
  }
}
.who_pay_header {
  align-self: flex-start;
  color: $text-black;
  padding-top: 22px;
}

.who-pay-chart {
  width: 200px;
  text-align: left;
  height: 200px;
  margin: 15px 30px 15px 0;
}
.whopay-chart-legend {
  margin-top: 30px;

  .legend {
    margin-bottom: 10px;
    display: flex;

    .color-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-top: 3px;
    }

    span {
      margin-left: 15px;
    }
  }
}

@keyframes colorChangeAnimation {
  to {
    background: #00c880;
  }
}

.dashboard-user-icon {
  width: 15px;
  margin-right: 20px;
  margin-top: -3px;
}

#prp_detail, .popup-wrap {
  .center_bx {
    margin: 0 0 0 auto;

    .middle_btn {
      color: #000;
      font-weight: 400;
      font-family: $pregu;
      text-transform: uppercase;
      border: 1px solid #000;
      padding: 11px 30px 10px;
      border-radius: 20px;
      font-size: 12px;
      letter-spacing: 1px;

      &:hover {
        background: #000 !important;
        color: #fff !important;
      }
    }
  }
}

.prpty_projection {
  .center_bx {
    margin: auto;
    padding: 17px 0 16px;

    .middle_btn {
      color: $text-green;
      font-weight: 400;
      font-family: $pregu;
      text-transform: uppercase;
      border: 1px solid $text-green;
      padding: 11px 30px 10px;
      border-radius: 20px;
      font-size: 12px;
      letter-spacing: 1px;

      &:hover, &.active {
        background: $text-green;
        color: #fff !important;
        box-shadow: none !important;
      }
    }
  }

  .btn-loading {
    height: calc(100% + 30px);
    top: -14px;
  }
}

/*Depreciation of Fitting dialog*/
.dp {
  .custom-control {
    padding-left: 10px;
  }

  .custom-control-label {
    padding-left: 10px;

    &:after, &:before {
      top:3px !important;
    }
  }

}

/*Extra repayment Start*/
.c3 {
  svg {
    font: 10px "ProximaNova-Regular";
  }
}

.extra_repayment_bx {
  .left_bx, .right_bx {
    width: 50%;
    padding-top: 19.5px;
    padding-bottom: 19.5px;

    &.slct_bx {
      padding-top: 10.5px;
      padding-bottom: 10.5px;
    }
  }

  .right_bx {
    padding-left: 19.5px;
    display: flex;
    border-left: 1px solid #97979733;

    span {
      width: 100%;
      text-align: right;
      color: $text-green;
    }
  }

  .repayment-amount {
    display: flex;

    .label-txt {
      width: 33%;
      height: calc(2.0625rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 0.875rem;
      line-height: 1.5;
    }

    .crita {
      width: 33%;
      top:0;

      .css-10nd86i {
        width: 100%;
      }

      .css-11ann8a {
        min-height: 30px;
      }
    }

    .repayment-input {
      width: 34%;
      float: right;
      text-align: right;
    }
  }

  .repayment-amount-start {
    display: flex;

    .label-txt {
      width: 50%;
      height: calc(2.0625rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 0.875rem;
      line-height: 1.5;
    }

    .repayment-input {
      width: 50%;
      float: right;

      text-align: right;
    }
  }

  .repayment-chart {
    width: 100%;
    height: 500px;
    padding: 50px 10px;
  }
}
/*Extra repayment end*/

.hide {
  display: none !important;
}


#subscription{
  .sub_bx{
    padding: 60px 0;

    .dual_switch .s_cat.mv{
      position: relative;
      top: -6px;
      right: -10px;
    }

    .dual_switch .s_cat span{
      position: relative;
      right: -96px;
      top: -14px;
    }
    .charge{
      color: $text-gdark;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin: 55px 0 60px;
      .dlr{

      }
      .per{
        text-align: left;
        font-size: 14px;
      }
      .amnt{
        font-size: 90px;
        color: #808080;
        color: #808080;
        line-height: 52px;
      }
    }
    .dual_switch{
      height: 41px;
    }

    .jumbo_btn{
      background: $text-green;
      font-family:$pregu;
      /* display: inline-block !important; */
      text-transform: uppercase;
      color: #fff;
      font-size: 14px;
      letter-spacing: 1.5px;
      padding: 19px 40px !important;
      border-radius: 30px;
      width: auto;
      border:2px solid $text-green;
      margin: 50px auto 0;

      &:hover{
        background: #fff !important;
        color: $text-green;
      }
    }

    .plan_list{
      background: transparent !important;
      border:none !important;
      margin: 0 !important;
      padding: 0 !important;
      display: inline-block;

      li{
        color:$text-gdark;
        line-height: 29px;
        font-size: 18px;
        list-style-type: none;

        &:before{
          content: ".";
          font-size: 25px;
          position: relative;
          top: -3px;
          line-height: 15px;
          left: -3px;
          color: #afafaf;
        }
      }
    }
  }
  .auth-footer{
    border-top: 1px solid #f2f2f2;
    justify-content: flex-end;
  }

  .table thead th:last-child{
    text-align: right;
    padding-right: 40px;
    padding-left: 0;
  }
  .table th, .table td{
    padding-left: 40px;
    color: #808080;
    font-weight: normal;
    border-bottom: 1px solid #dee2e6;
  }
  .table a{
    color: #808080;
    &:hover{
      color:$text-green;
    }
  }

  .table {
    .active {
      color:$text-green;
    }
  }
  .act_btn a{
    padding: 0 2px;
    display: inline-block;
  }
  .left_bx{
    width:auto;
  }
  .css-1hwfws3{
    padding-left: 0;
  }
  .tp_row .inner_row{
    margin-left: 0;
  }

  .cancel-sub {
    font-size: 12px;
  }

  .right_bx a{
    color: $text-gdark;
  }

  .description {
    display: block;
    text-align: left;
    width: 100%;
  }
}

.form-control:disabled, .form-control[readonly], .btn-secondary.disabled, .btn-secondary:disabled {
  background-color: transparent;
}

.table tbody + tbody{border: none !important;}
.admin-arrow:hover {
  cursor: pointer;
}

#thank-you {

  padding: 40px 20px 20px;

  .btn-div {
    margin-top: 20px;
    margin-bottom: 20px;

    .thank-you-btn {
      font-size:13px;
      color:#000000;
      border-radius:30px;
      font-weight: 700;
      padding:13px 20px 10px 20px;
      border:1px solid #000000;
      letter-spacing: 1px;
      width: 175px;
      display: block;
      text-align:center;
      line-height:20px;
      text-decoration:none;
      mso-line-height-rule: exactly;
    }
  }
}

.reset-bx {
  .right_bx {
    padding: 25px 35px !important;
    width: auto !important;
    border-right: 1px solid #f2f2f2;

    a {
      color: $text-gdark;
    }

    a:hover, a:active {
      color: $text-black;
    }
  }
}

.forgot-password {
  text-align: right;
  margin-top: -30px;
  padding-right: 20px;

  a {
    color: $text-black;

    &:hover, &:active {
      color: $text-gdark;
    }
  }

}

.custom-ac-link {
  color: $text-gdark;

  &:hover, &:active {
    color: $text-black;
  }
}

.prop-used-as {
  color: #222;
  font-family: $pmedium;
}


//CSS FOR TOUR
.introjs-helperLayer {

  background-color: #FFF !important;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 15px rgba(0,0,0,.2);
}
.introjs-tooltip{
  min-width: 380px;
  padding: 0 !important;
  border-radius:0 !important;
  background: #fff !important;
  box-shadow: none !important;
}
.introjs-tooltiptext{
  color: #08c880;
  font-size: 28px;
  padding: 45px 40px 40px;
  line-height: 40px;
  font-family: $plight;
}
.introjs-tooltipbuttons{
  text-align: left;
  padding: 20px 28px;
  border-top: 1px solid #e4e4e4;
}
.introjs-helperNumberLayer{
  display: none !important;
}
.introjs-bullets{
  display:none !important;
}
.introjs-helperLayer.step1{
  /*top: 80px !important;*/
  //    left: 122px !important;
}
.introjs-helperLayer.step2{
  height: 48px !important;
  /* top: 75px !important;*/
}
.introjs-overlay{
  background: #666 !important;
  /*background:url(../../images/blur.png);*/
}
//.step1_tooltip{
//    top: -21px;
//    margin-left: 15px;
//}
//.introjs-tooltip{
//  text-align: center;
//}
.introjs-donebutton{
  float:right;
}

.introjs-tooltip{
  -webkit-transition: opacity 0.3s ease-out !important;
  transition: opacity 0.3s ease-out  !important;
}
/*.introjs-helperLayer{
    opacity: 1 !important;
 -webkit-transition: opacity 0.5s ease-out !important;
    transition: opacity 0.5s ease-out !important;
}
.introjs-helperLayer.step1{
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-out !important;
    transition: opacity 0.5s ease-out !important;
}
.introjs-helperLayer.step2{
    opacity: 1;
    -webkit-transition: opacity 0.5s ease-out !important;
    transition: opacity 0.5s ease-out !important;
}*/
.introjs-prevbutton.introjs-fullbutton,.introjs-button{
  background: #fff !important;
  border: none;
  color: #151515;
  border-radius: 36px !important;
  font-size: 14px;
  /* text-transform: uppercase; */
  text-decoration: none;
  font-family: $pregu;
  margin-top: 0;
}
.introjs-nextbutton.introjs-fullbutton{
  float: right;
}
.introjs-button:hover{
  color: #12cb88;
}
.introjs-nextbutton:after{
  content: "\E606";
  font-family: 'simple-line-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: 11px;
  padding-left: 6px;
}

.introjs-prevbutton:before{
  content: "\E605";
  font-family: 'simple-line-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: 11px;
  padding-right: 5px;
}
.subtit{
  color: #000 !important;
  display: block;
  font-size: 13px;
  padding-top:15px;
  line-height: 17px;
}

/*Start placeholder hide*/
::-webkit-input-placeholder { opacity: 1; -webkit-transition: opacity .5s; transition: opacity .5s; }  /* Chrome <=56, Safari < 10 */
:-moz-placeholder { opacity: 1; -moz-transition: opacity .5s; transition: opacity .5s; } /* FF 4-18 */
::-moz-placeholder { opacity: 1; -moz-transition: opacity .5s; transition: opacity .5s; } /* FF 19-51 */
:-ms-input-placeholder { opacity: 1; -ms-transition: opacity .5s; transition: opacity .5s; } /* IE 10+ */
::placeholder { opacity: 1; transition: opacity .5s; } /* Modern Browsers */

*:focus::-webkit-input-placeholder { opacity: 0; } /* Chrome <=56, Safari < 10 */
*:focus:-moz-placeholder { opacity: 0; } /* FF 4-18 */
*:focus::-moz-placeholder { opacity: 0; } /* FF 19-50 */
*:focus:-ms-input-placeholder { opacity: 0; } /* IE 10+ */
*:focus::placeholder { opacity: 0; } /* Modern Browsers */
/*End placeholder hide*/

/*Start mobile screen css*/
.mobile-screen {
  width: 100%;
  height: 100vh;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;

  .download-tab {
    width: 100%;
    height: 40px;

    .download-content {
      display: flex;
      width: 100%;
      padding: 10px;
      background: #f2f2f2;

      i {
        padding: 25px 0;
      }

      img {
        width: 70px;
        height: 70px;
      }

      .header {
        font-size: 14px;
        color: #000;
        padding: 25px 0 25px 10px;
      }

      .download {
        color: #037DFD;
        font-size: 14px;
        text-transform: uppercase;
        text-align: right;
        padding: 25px 0;
        width: 100%;
      }
    }
  }

  .content {
    margin: auto;
    text-align: center;
    padding-top: calc(50vh - 220px);

    img {
      width: 100px;
      height: 100px;
    }

    .header {
      color: $text-greendark;
      font-size: 25px;
      margin: 40px 0 40px;
    }

    .download {
      margin: 20px 0;
      color: $text-green;
      font-size: 22px;
      font-weight: bold;
    }
  }
}
/*End mobile screen css*/

.modal-footer {
  .btn {
    border: 1px solid;
    padding: 5px 30px;
    border-radius: 30px;

    &:hover {
      color: #ffffff;
      background-color: #000 !important;
      border: 1px solid #000;
    }
  }
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    transform: translateY(0);
  }
}

.fixed-header {
  position: fixed;
  width: 100%;
  animation: slide .3s forwards;
  -moz-animation: slide .3s forwards;
  -webkit-animation: slide .3s forwards;
  -o-animation: slide .3s forwards;
}

.popup-wrap .loc_tab span.active {
  text-decoration: none;
  position: relative;
}

.popup-wrap .loc_tab span.active:after {
  content: '';
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -3px;
  border-width: 0 0 2px;
  border-style: solid;
}

.super-annua {
  margin-left: 0;
}

.password-note-group {
  padding-bottom: 0 !important;
}

.password-note-group input {
  padding-bottom: 0 !important;
}

.password-note {
  margin-left: 191px;
  font-size: 11px;
  margin-bottom: 5px !important;
}

.sidebar-link {
  .inactive-menu {
    display: block;
  }

  .active-menu {
    display: none;
  }

  &.active {
    .inactive-menu {
      display: none;
    }

    .active-menu {
      display: block;
    }
  }
}

.selected {
  .sidebar-link {
    background-color: #00c880 !important;
  }
}

.client_wrap .table-wrap table tr th:first-child {
  padding-left: 30px;
}

.client_wrap .table-wrap table tr th:last-child {
  padding-right: 30px;
}

.search_by {
  .css-1rtrksz {
    padding-left: 2px;
  }
}

@media(max-width:1024px){
  .mb_w {
    min-width:220px !important;
  }
  .table-wrap{
    margin-top: 30px;
  }
  .w_130{
    min-width:130px !important;
  }
  .client_wrap {
    .col-md-6{
      max-width: 100%;
      flex: 0 0 100%;

      .row{
        .col-md-6{
          flex: 0 0 50%;
        }
      }
    }
  }

  .who-pay-chart {
    height: 100px;
    width: 100px;
  }
}
@media(max-width:1400px){
  .rect_bx .tp_row .fx_w, .rect_bx .head_tron .fx_w {
    width: 145px;
    min-width: 145px;
  }
  .rect_bx .tp_row{
    padding: 5px 30px;
  }
  .rect_bx .head_tron {
    padding: 32px 30px;
  }
  .rect_bx .tp_row .act_icon {
      padding: 18px 10px 18px 0;
  }
  .pr-40 {
      padding-right: 30px;
  }
  .rect_bx .amenities .hd_row {
    padding: 10.5px 30px;
  }
  .who-pay-chart {
    height: 150px;
    width: 150px;
  }
}

@media(min-width: 1401px) and (max-width:1700px){
  .who-pay-chart {
    height: 170px;
    width: 170px;
  }
}


@media(max-width:767px){
  .topbar{
    padding: 10px 8px;
  }
  .navbar-header{
    position: relative;
    width: 100%;
  }
  .topbar .top-navbar .navbar-header{
    width: 100%;

  }
  .topbar .nav-toggler{
    position: absolute;
    right: 5px;
    font-size: 21px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    box-shadow: 0 1px 10px #33333333;
    text-align: center;
    line-height: 47px;
    padding: 0 5px;
    color: #08c880 !important;
  }
  .topbar .topbartoggler{
    display:none !important;
  }
  .topbar .navbarbg.navbar-collapse{

    display: flex;
    align-items: center;
    padding-right: 0;
    width: 100%;
    z-index: 9;
  }
  .topbar .navbarbg.navbar-collapse .float-left.navbar-nav{
    position: absolute;
    right: 3px;
    top: 51px;
  }
  .topbar .navbarbg.navbar-collapse .float-right.navbar-nav{
    position: absolute;
    right: 62px;
    top: 0;
  }
  .topbar .top-navbar .profile .letter-badge{
    margin-right: 0;
  }
  .dropdown.nav-item .fa-caret-down {
    display: none;
  }
  .page-wrapper{
    position: relative;
    top: 13px;
  }
  .topbar .top-navbar .nav-item .nav-link{
    line-height: 30px !important;
  }
  .left-sidebar{
    padding-top: 65px;
  }
  .rect_bx .tp_row .act_icon{
    padding: 18px 10px !important;
  }
  .rect_bx .tp_row .slct{
    /*display: block !important;*/
    border-left: 1px solid #efefef;
  }
  .rect_bx .tp_row .form-control{
    font-size: 12px;
  }
  //    .rect_bx .tp_row .p_type_cat{
  //        border:none;
  //    }
  .mb_w {
    min-width: 130px !important;
  }
  .left-sidebar .sidebar-nav .sidebar-item .sidebar-link span{
    padding: 0 10px;
    font-size: 13px;
  }
  .popup-wrap .loc_tab span{
    padding: 0 4px;
    font-size: 12px;
  }
  .popup-wrap .tab_grid span {
    font-size: 12px;
  }
  .rect_bx .tp_row_btm .right_bx,.popup-wrap .rect_bx .left_bx,#userdetail .right_bx,.auth-box .auth-footer .left_bx {
    padding: 15px 10px;
    width: 105px;
  }
  .popup-wrap .rect_bx .left_bx,.rect_bx .tp_row_btm .left_bx{
    padding:15px 10px;
    width: 105px;
  }
  #subscription .rect_bx .head_tron {
    padding: 15px 10px;
  }
  .hd_row.pr-40{
    padding-right: 10px;
  }
  #userdetail .dlt_btn{
    padding: 5px 10px;
  }
  .rect_bx .tp_row .inner_row{
    position: relative;
    left: 0;
  }
  #subscription .head_tron .head{
    font-size: 13px;
    padding: 0 7px;
  }
  #subscription .table th, #subscription .table td{
    padding: 10px 5px;
    font-size: 12px;
  }
  .auth-box .input-group {
    padding: 10px 10px;
  }

  #subscription .mt-5{
    margin-top: 1rem !important;
  }
  .auth-box{
    margin: 10% auto !important;
  }
  .table-wrap {
    margin-top: 15px;
  }
  .rect_bx .tp_row .value{
    font-size: 12px;
    padding-right: 0px !important;
  }
  .tp_row_btm .btn{
    font-size: 14px;
  }
  .popup-wrap .rect_bx form>.tp_row .fx_w{
    width: 80px;
    min-width: 80px;
  }
  .popup-wrap form{
    width: 100%;
    overflow-x: scroll;
  }
  .page-wrapper > .page-content {
    padding: 10px !important;
  }
  .prpty_snapshot .map {
    height: auto;
  }
  
    
    .rect_bx {
    margin-bottom: 10px;
  }
  .prpty_projection  .plisting_form.rect_bx {
    .head_tron{
      padding: 45px 15px 19px !important;
      overflow-x: scroll;
      width: 100%;
      max-width: 100%;
    }
    .crita{
      position: absolute;
      right: 9px;
      height: 10px;
      top: 6px;
    }
  }
  .rect_bx .head_tron{
    padding: 25px 10px !important;
    .head {
      font-size: 15px !important;
    }
  }
  #prp_detail .center_bx {
    margin: 0 5px !important;
    .middle_btn{
      padding: 8px 14px 7px !important;
    }
  }
  .prpty_projection .inner_bx{
    overflow-y: scroll;
    height: 500px;
    max-width: 100%;
    width: 100%;
    overflow-x: hidden;
  }
  .prpty_projection .inner_bx .tp_row{
    overflow: scroll;
    width: 100%;
    max-width: 100%;
  }
  .prpty_projection .rect_bx .tab_grid span{
    width: 80px;
  }
  .prpty_projection  .crita span{
    width: auto !important;
  }
  .client_wrap .col-md-6 .row .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bx_info h5{
    font-size: 15px;
    img{
      margin-right: 15px !important;
    }
  }
  .client_wrap .sm_bx{
    padding: 20px;
    height: 200px;
  }
  .client_wrap .sm_bx .bx_info .num{
    font-size:30px;
  }
  .client_wrap .wt_bx .top_part{
    padding: 15px;
  }
  .client_wrap .wt_bx .top_part {

    padding: 10px;
    position: relative;
  }
  .client_wrap .wt_bx .top_part .top_dtl{
    justify-content: space-between;
    display: block !important;
  }
  .client_wrap .wt_bx .top_part .top_dtl .top_mdl{
    width: 100%;
    margin-top: 10px;
  }
  .client_wrap .wt_bx .top_part .prpt_img{
    margin: 0 auto;
    display: block;
  }
  .client_wrap .wt_bx .top_part .top_dtl .top_mdl .prop_add{
    padding-left: 15px;
    line-height: 16px
  }
  .client_wrap .wt_bx .btm_part {
    padding: 15px;
  }
  .al_btm .f16{
    font-size: 14px;
  }
  .client_wrap .table-wrap table tr td{
    font-size: 12px;
    line-height: 1.5;
    padding: 10px !important;
  }
  .client_wrap .table-wrap table tr th:first-child {
    min-width: 100px;
  }
  .page-content{
    padding: 30px 15px;
  }
  .top_mdl .d-flex{
    justify-content: space-between;
  }
  .client_wrap .prpty_card .icon-arrow-right{
    position: absolute;
    right: 10px;
    bottom: 13px;
  }
  .client_wrap .table-wrap table .read-more{
    position: static !important;
  }
  #prpty_list .table-wrap table .read-more{
    position: relative !important;
  }
  .client_wrap .table-wrap table tr th{
    padding: 10px !important;
  }
  .superadmin .wt_bx .top_part .top_dtl{
    display: flex !important;
  }
  .client_wrap .wt_bx .top_part .top_dtl .ttl_count .ttl_num{
    font-size:35px;
  }
  .show-sidebar .ti-close:before {
    content: "\E646" !important;
  }
  .auth-box .card {
    margin: 10px auto !important;
  }
  .auth-box .input-group {
    padding: 8px 12px !important;
    justify-content: center;
  }
  .auth-box .input-group-prepend .input-group-text {
    min-width: 110px;
  }
  .auth-box .auth-footer .right_bx {
    padding: 8px;
    justify-content: space-evenly;
  }
  .auth-box .auth-footer .social span{
    display: block !important;
    font-size: 10px;
    line-height: 11px;
  }
  .auth-box .auth-footer .left_bx {
    padding: 15px 10px;
  }
  .auth-box .auth-footer .social img{
    padding-right: 0;
    margin-right: 0;
  }
  pro_faci{
    padding-top: 7px;
  }
  .client_wrap .table-wrap table{
    .tab_btn{
      padding-top: 5px;
    }
    a.tab_btn {
      padding: 6px 9px !important;
    }
  }
  .prpty_snapshot .upload-section .preview{
    height: 60px;
    .preview-block{
      height: 60px;
    }
  }
  .extra_repayment_bx{
    .tp_row {
      padding: 10.5px 5px !important;

      .css-1rtrksz{
        padding-left: 0;
      }
      .right_bx {
        padding-left: 6.5px;
        border-top: 1px solid hsla(0,0%,59.2%,.2);
        border-left:none !important;
      }
      .crita{
        position: static !important;
      }    
    }
    .hd_row.d-flex{
      display: block !important;
    }
    .left_bx, .right_bx{
      width: 100% !important;
      align-items: center;
    }

    .repayment-amount{
      .label-txt{
        height: auto !important;
        padding-left: 0 !important;

      }
      .repayment-input{
        padding: 5px 0px 5px !important;
      }
      .crita{
        width: 42% !important;
        height: auto !important;
      }
    }
    .repayment-amount-start{
      .label-txt{
        padding-left: 0 !important;
        padding-right: 0 !important;
        font-size: 13px;
        line-height: 1;
        color: #808080 !important;
      }
    }

  }
  .loc_type_opt {
    width: 129px;
  }

}

.loan_term .css-1rtrksz{
   width: 45px !important; 
}

@media (max-width:1099px){
  .rect_bx .tp_row .fx_w, .rect_bx .head_tron .fx_w {
    width: 120px;
    min-width: 120px;
    font-size: 13px;
  }
  .rect_bx .tp_row {
    padding: 10.5px 15px;
  }
  .rect_bx .head_tron {
    padding: 30px 20px;
  }
    #prp_detail .amenities .hd_row {
    width: 33%;
    min-width: 33%;
    padding: 10.5px 6px 10.5px 15px;
      display: block !important;
  }
  
  #prp_detail  .amenities .hd_row .sm_tit {
    padding-right:0;
    text-align: center;
  }
}

@media (max-width:1023px){
  .page-wrapper .col-md-6{
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media(min-width:1024px) and (max-width:1099px){
  .pad-cash-flow {
    padding-bottom: 76px;
  }
}

@media(min-width:1100px) and (max-width:1400px){
  .pad-cash-flow {
    padding-bottom: 54px;
  }
}

@media(min-width:1401px) and (max-width:1700px){
  .pad-cash-flow {
    padding-bottom: 31px;
  }
}

@media(max-width:500px) {
  .super-annua {
    margin-left: -45px;
  }
}

@media(min-width:501px) and (max-width:519px) {
  .super-annua {
    margin-left: -50px;
  }
}

@media(min-width:520px) and (max-width:540px) {
  .super-annua {
    margin-left: -55px;
  }
}

@media(min-width:541px) and (max-width:767px) {
  .super-annua {
    margin-left: -60px;
  }
}

@media(min-width:768px) and (max-width:1023px) {
  .super-annua {
    margin-left: -45px;
  }
}

@media(min-width:1280px) and (max-width:1320px) {
  .super-annua {
    margin-left: -10px;
  }
}

@media(min-width:1321px) and (max-width:1485px) {
  .super-annua {
    margin-left: -15px;
  }
}

@media(min-width:1486px) and (max-width:1500px) {
  .super-annua {
    margin-left: -20px;
  }
}

@media(min-width:1501px) and (max-width:1599px) {
  .super-annua {
    margin-left: -20px;
  }
}

@media(min-width:1600px) {
  .super-annua {
    margin-left: -45px;
  }
}